<template>
  <v-card class="single-metric" min-height="100" :loading="loading">
    <v-card-title
      style="
        padding: 0 0 0 5px;
        height: 35px;
        align-items: center;
        background-color: #e0e0e0;
      "
    >
      <h5>{{ title }}</h5>
      <!--<v-spacer />
       <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn> -->
    </v-card-title>
    <v-row no-gutters align="center" style="height: 65px">
      <v-col cols="8" class="text-h4 d-flex justify-center">
        {{ metric }}
      </v-col>
      <v-col cols="4" class="d-flex justify-start">
        <v-icon x-large color="grey lighten-2">
          {{ icon }}
        </v-icon>
      </v-col>
      <help :helpId="helpId" :style="customStyles" />
    </v-row>
  </v-card>
</template>
<script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
      metric: {
        type: [String, Number],
        default: '',
        required: true,
      },
      customStyles: {
        type: String,
      },
      icon: {
        type: String,
        required: true,
      },
      loading: {
        type: Boolean,
        required: true,
      },
      helpId: {
        type: Number,
        required: true,
      },
    },
    methods: {
      onCellClick() {
        this.$modal.show('helpModal')
      },
    },

    beforeDestroy() {},
  }
</script>
<style lang="scss">
  .single-metric {
    .v-toolbar__content .v-btn.v-btn--icon.v-size--default,
    .v-toolbar__extension .v-btn.v-btn--icon.v-size--default {
      height: 20px;
      width: 20px;
    }
    .v-toolbar__content {
      padding: 5px 10px;
      height: 100% !important;
    }
    .v-btn--fab.v-size--small {
      height: 20px;
      width: 20px;
    }
  }
</style>
