<template>
  <div class="vuse-content-wrapper">
    <v-container fluid>
      <horizontal-menu :menuItems="menuItems" />

      <v-row align="center">
        <v-col cols="12" sm="6" lg="3" md="4">
          <single-metric
            :title="'Cursos activos'"
            :metric="stats.activeCourses ? Math.ceil(stats.activeCourses) : 0"
            :icon="'mdi-clipboard-edit-outline'"
            :loading="loading"
            :helpId="39"
            :customStyles="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>
        <v-col cols="12" sm="6" lg="3" md="4">
          <single-metric
            :title="'Lecciones activas'"
            :metric="stats.activeLessons ? Math.ceil(stats.activeLessons) : 0"
            :icon="'mdi-order-bool-ascending'"
            :loading="loading"
            :helpId="40"
            :customStyles="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>
        <v-col cols="12" sm="6" lg="3" md="4">
          <single-metric
            :title="'Progreso General'"
            :metric="
              stats.trainingPercentage
                ? Math.ceil(stats.trainingPercentage) + '%'
                : 0 + '%'
            "
            :icon="'mdi-chart-line-variant'"
            :loading="loading"
            :helpId="41"
            :customStyles="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>
        <v-col cols="12" sm="6" lg="3" md="4">
          <single-metric
            :title="'Tiempo de capacitación'"
            :metric="
              stats.trainingTime ? Math.ceil(stats.trainingTime) + ' min' : 0 + 'min'
            "
            :icon="'mdi-clipboard-clock'"
            :loading="loading"
            :helpId="42"
            :customStyles="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>
      </v-row>

      <!-- <div :is="currentComponent" :metrics="dashboard" /> -->
    </v-container>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { decryptData } from '@/utils/encryption'
  import SingleMetric from '@/components/Stats/SingleMetric'
  import HorizontalMenu from '@/components/App/HorizontalMenu'
  export default {
    components: {
      HorizontalMenu,
      SingleMetric,
    },
    data: () => ({
      stats: {},
      loading: false,
      menuItems: [
        {
          id: 1,
          name: 'Cursos y Lecciones',
          icon: 'mdi-clipboard-edit-outline',
          link: '/business/elearning/courses',
          requiredRoles: ['super_admin', 'admin', 'admin_courses'],
        },
        {
          id: 1,
          name: 'Desempeño de la red',
          icon: 'mdi-gauge-full',
          link: '/business/elearning/traning-performance',
          requiredRoles: ['super_admin', 'admin', 'admin_learning'],
        },
      ],
    }),
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
    },
    created() {
      this.getStats()
    },
    methods: {
      getStats() {
        this.loading = true
        this.$axios
          .get('stats-management/elearning-stats?company_id=' + this.company.id)
          .then((response) => {
            this.stats = response.data
            this.loading = false
          })
      },
    },
  }
</script>
