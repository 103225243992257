<template>
  <v-bottom-navigation color="teal" class="my-4" height grow scroll-threshold="500">
    <v-btn
      v-for="(menuItem, index) in menuItems"
      :key="index"
      x-large
      @click="triHolis(menuItem.link)"
      class="py-2"
    >
      <span
        style="
          font-size: 11px;
          float: left;
          width: 100px;
          clear: both;
          white-space: normal;
        "
      >
        {{ menuItem.name }}
      </span>

      <v-icon large>
        {{ menuItem.icon }}
      </v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
  export default {
    props: {
      menuItems: {
        type: Array,
        required: true,
      },
      method: {
        type: Function,
      },
    },

    methods: {
      triHolis(val) {
        if (val === '#') {
          this.$emit('open-modal', val)
        } else {
          this.$router.push({ path: val })
        }
      },
    },
  }
</script>
